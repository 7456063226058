@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@400;700&display=swap");

.box,
.box-reverse {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 800px;
  margin: 30px auto 30px auto;
  border: solid 1px rgb(245, 245, 245);
  border-radius: 15px;
  background-color: rgb(239, 239, 239);
}

.box-reverse {
  flex-direction: row;
}

.text-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: gold;
  margin-bottom: 20px;
}

.box-text {
  width: 50%;
  padding: 30px;
  text-align: justify;
}

.box-img {
  width: 50%;
  border-radius: 15px;

  img {
    width: 100%;
    border-radius: 15px;
  }
}

.box-brecho {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 40px auto 20px auto;
  border: solid 1px whitesmoke;
  border-radius: 15px;
  background-color: rgb(176, 233, 255);
  padding: 20px;
}

.box-brecho-text {
  width: 100%;
  text-align: justify;
}

.box-brecho-img {
  width: 100%;
  border-radius: 15px;
}

.box-brecho-title {
  font-family: "Kalam", Arial;
  width: 100%;
  max-width: 800px;
  text-align: center;
  font-size: 80px;
  font-weight: bold;
  color: orange;
  padding: 0;
  height: 100px;
}

@media (max-width: 600px) {
  .box,
  .box-reverse {
    flex-direction: column;
    max-width: 100%;
    margin: 20px auto 20px auto;
    border-radius: 5px;
  }

  .box-reverse {
    flex-direction: column-reverse;
  }

  .text-title {
    font-size: 30px;
  }

  .box-text {
    width: 95%;
    padding: 10px;
  }

  .box-img {
    width: 100%;
    border-radius: 5px;

    img {
      width: 96%;
      border-radius: 5px;
      margin: auto;
    }
  }

  .box-brecho {
    width: 100%;
    max-width: 800px;
    border-radius: 1px;
    margin: 40px auto 20px auto;
    padding: 20px;
  }
}
