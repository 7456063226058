.footer {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  margin: 20px auto 40px auto;
  background-color: #3f69a8;
  color: white;
  font-size: 20px;
  border-radius: 15px;
}

@media (max-width: 600px) {
  .footer {
    border-radius: 1px;
  }
}
