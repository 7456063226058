@import url("https://fonts.googleapis.com/css2?family=Vast+Shadow:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy:wght@400;700&display=swap");



#grid-logo {
  margin:10px 0 0 0;
}
#grid-presentation {
  margin: 20px 0 0 0;
}

.header-img {
  width: 100%;
  max-width: 400px;
  border-radius: 15px;
  margin: auto;
}

.header-text {
  width: 100%;
  max-width: 400px;
  font-family: "Vast Shadow";
  font-size: 42px;
  color: rgba(4, 109, 179, 1);
}

.presentation {
  width: 100%;
  max-width: 600px;
  border-radius: 15px;
  text-align: justify;
  padding: 15px;
  background-color: #3f69a8;
  color: white;
  margin-bottom: 30px;
}

.presentation p::first-letter {
  font-family: "Satisfy";
  font-size: 1em;
  font-weight: bold;
  color: yellow;
}

@media (max-width: 600px) {

  #grid-logo {
    margin: 5px;
  }
  
  .header-img {
    border-radius: 1px;
    min-width: 100%;
  }

  .header-text {
    width: 100%;
    max-width: 100%;
    font-family: "Vast Shadow";
    font-size: 40px;
  }
  #grid-presentation {
    margin: 20px 0px 20px 0px;
  }
  .presentation {
    padding: 15px;
    max-width: 90%;
    margin: auto;
    border-radius: 1px;
  }

}